import axios from "axios";
import Dexie from "dexie";
import {
  importDB,
  exportDB,
  importInto,
  peakImportFile,
} from "dexie-export-import";

export const STORE = {
  GAME_LIBRARY: "game_library",
  GAMES: "games",
  TAGS: "tags",
  HISTORY: "history",
  CONFIG: "config",
  GENRES: "genres",
};

const _db = new Dexie("m4rv1n");
_db.version(1).stores({
  [STORE.GAME_LIBRARY]: "++id,game_id",
  [STORE.GAMES]: "++id",
  [STORE.TAGS]: "++id",
  [STORE.GENRES]: "++id",
  [STORE.HISTORY]: "++id,game_id,tag_id,genre_id",
});

export const db = _db;
export const gameLibrary = _db[STORE.GAME_LIBRARY];
export const games = _db[STORE.GAMES];
export const usage = _db[STORE.USAGE];

export const importTestDB = async () => {
  axios({
    url: "/games.json",
    method: "GET",
    responseType: "blob", // important
  }).then(({ data }) => {
    importDB(data)
      .then((a) => {
        window.location.href = "/library";
      })
      .catch((a) => {
        window.location.href = "/library";
      });
  });
};
