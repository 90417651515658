import React from "react";
import ReactDOM from "react-dom";
import { App } from "./App";
import { humanFileSize } from "./core/util/file";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import quickp2p, { SimpleStore } from "quickp2p";
import "normalize.css";
import "nes.css/css/nes.min.css";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { FirebaseAuthProvider } from "@react-firebase/auth";
import { firebaseConfig } from "./core/config/firebase.config";
import { GlobalStyle } from "./core/global.style";

ReactDOM.render(
  <FirebaseAuthProvider firebase={firebase} {...firebaseConfig}>
    <GlobalStyle />
    <App />
  </FirebaseAuthProvider>,
  document.getElementById("root")
);

quickp2p.setStore(
  new SimpleStore("https://simple-key-value-store.herokuapp.com")
);

const debug = async () => {
  if (navigator.storage && navigator.storage.estimate) {
    const quota = await navigator.storage.estimate();
    // quota.usage -> Number of bytes used.
    // quota.quota -> Maximum number of bytes available.
    const percentageUsed = (quota.usage / quota.quota) * 100;
    const { usage, quota: q } = quota;
    const remaining = quota.quota - quota.usage;
    console.log({
      quota: humanFileSize(q),
      usage: humanFileSize(usage),
      remaining: humanFileSize(remaining),
      percentageUsed,
    });
  }
};

debug();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
