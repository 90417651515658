import React from "react";
import { withRouter } from "react-router-dom";
import "nes.icons/css/nes-icons.css";
import styled from "styled-components";

const HeaderWrapper = styled.div`
  position: fixed;
  bottom: 32px;
  right: 32px;
  display: none !important;
  z-index: 998;
  @media all and (display-mode: standalone) {
    display: block !important;
  }
`;

const HeaderBase = ({ history }) => {
  window.hist = history;
  return (
    <HeaderWrapper>
      <button className="nes-btn is-dark" onClick={() => history.go(-1)}>
        <i
          className="nes-icon caret-left is-small"
          style={{ fontSize: 18 }}
        ></i>
      </button>
      <button className="nes-btn is-dark" onClick={() => history.go(1)}>
        <i
          className="nes-icon caret-right is-small"
          style={{ fontSize: 18 }}
        ></i>
      </button>
    </HeaderWrapper>
  );
};

export const Header = withRouter(HeaderBase);
