import React, { Fragment, Suspense } from "react";
import styled from "styled-components";

import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { importTestDB } from "./core/config/indexed-db.config";
import { Header } from "./components/header.component";
import { compose, lifecycle } from "recompose";
import { Store } from "pullstate";

import app from "firebase/app";
import "firebase/analytics";
import { firebaseConfig } from "./core/config/firebase.config";

app.initializeApp(firebaseConfig);

export const AppStore = new Store({
  focused: true,
});

const PlayScreen = React.lazy(() =>
  import("./emulator/gui/PlayScreen").then((module) => ({
    default: module.PlayScreen,
  }))
);
const Play = React.lazy(() =>
  import("./scenes/play/play.component").then((module) => ({
    default: module.Play,
  }))
);
const AddGames = React.lazy(() =>
  import("./scenes/add-games/add-games.component").then((module) => ({
    default: module.AddGames,
  }))
);
const Library = React.lazy(() =>
  import("./scenes/library/library.component").then((module) => ({
    default: module.Library,
  }))
);
const Game = React.lazy(() =>
  import("./scenes/game/game.component").then((module) => ({
    default: module.Game,
  }))
);

const Genre = React.lazy(() =>
  import("./scenes/genre/genre.component").then((module) => ({
    default: module.Genre,
  }))
);

const Container = styled.div`
  position: static !important;
  padding: 0 !important;
  border: none !important;
  margin: 0 !important;
  &:after {
    content: none !important;
  }
`;

const AppBase = () => {
  return (
    <Container className="nes-container is-dark">
      <Router>
        <Fragment>
          <Suspense fallback={<div>loading...</div>}>
            <Header />
            <Switch>
              <Route exact={true} path="/" component={Library} />
              <Route exact={true} path="/2p/:token?" component={PlayScreen} />
              <Route exact={true} path="/play/:id" component={Play} />
              <Route exact={true} path="/game/:id" component={Game} />
              <Route exact={true} path="/genre/:id" component={Genre} />
              <Route exact={true} path="/add" component={AddGames} />
              <Route exact={true} path="/library" component={Library} />
              <Route
                exact={true}
                path="/super-secret-import-route"
                component={() => {
                  setTimeout(() => {
                    importTestDB();
                  }, 3000);
                  return <div>Importing...</div>;
                }}
              />
              <Route component={() => <Redirect to="/" />} />
              {/* <Route component={AuthComponent} /> */}
            </Switch>
          </Suspense>
        </Fragment>
      </Router>
    </Container>
  );
};

const setAppFocus = (focus) =>
  AppStore.update((s) => {
    s.focused = focus;
  });

const appDidFocus = () => {
  setAppFocus(true);
  console.log("focus");
};

const appDidBlur = () => {
  setAppFocus(false);
  console.log("blur");
};

const lifecycles = lifecycle({
  componentDidMount() {
    window.addEventListener("pageshow", appDidFocus);
    window.addEventListener("focus", appDidFocus);
    window.addEventListener("pagehide", appDidBlur);
    window.addEventListener("blur", appDidBlur);
    window.a = app.analytics();
  },
  componentWillUnmount() {
    window.removeEventListener("pageshow", appDidFocus);
    window.removeEventListener("focus", appDidFocus);
    window.removeEventListener("pagehide", appDidBlur);
    window.removeEventListener("blur", appDidBlur);
  },
});

export const App = compose(lifecycles)(AppBase);
